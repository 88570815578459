import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Modal, Button } from 'react-bulma-components';

import { hideConfirmModal } from 'reducers/modals';

const ConfirmModal = (props) => {
  const { title, message, confirmText, show, confirmAction } = props.modals.confirmModal;

  const close = () => {
    props.hideConfirmModal();
  };

  return (
    <Modal show={show} onClose={close}>
      <Modal.Card>
        <Modal.Card.Header onClose={close}>
          <Modal.Card.Title>
            {title}
          </Modal.Card.Title>
        </Modal.Card.Header>
        <Modal.Card.Body>
          <div style={ ({ marginBottom: '10px' }) }>
            {message}
          </div>
          <Button
            color="danger"
            onClick={confirmAction}
            style={ ({ marginRight: '5px' }) }>{confirmText}</Button>
          <Button
            color="success"
            onClick={close}>Cancel</Button>
        </Modal.Card.Body>
      </Modal.Card>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  modals: PropTypes.object,
  hideConfirmModal: PropTypes.func
};

const mapStateToProps = (state) => ({
  modals: state.modals
});

export default connect(mapStateToProps, {
  hideConfirmModal
})(ConfirmModal);
