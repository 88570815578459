import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { changeLevelModified } from 'reducers/settings';
import { setTimeLimits } from 'reducers/level';
import DurationSelector from './durationSelector';

const TimeLimits = (props) => {
  const { timeLimits } = props.level;

  let [ limit0, limit1, limit2 ] = timeLimits;

  const inputChanged = (value, limit) => {
    let newLimits = [ ...timeLimits ];
    newLimits[limit] = value;
    props.setTimeLimits(newLimits);
    props.changeLevelModified(true);
  };

  const styles={
    border: props.settings.tutorialPhase === 1 ? '2px dashed red' : undefined
  };

  return (<div className="timeLimitEditor" style={styles}>
    <h1>Time limits:</h1>
    <div className='tlContainer'>
      <div className='tlstarContainer'>
        <img src='/images/star.png' alt='' className='tlstar'/>
      </div>
      <DurationSelector
        value={limit0}
        setValue={ (value) => inputChanged(value, 0) }
        hasError={limit0 === 0}/>
    </div>
    <div className='tlContainer'>
      <div className='tlstarContainer'>
        <img src='/images/star.png' alt='' className='tlstar'/>
        <img src='/images/star.png' alt='' className='tlstar'/>
      </div>
      <DurationSelector
        value={limit1}
        setValue={ (value) => inputChanged(value, 1) }
        hasError={limit1 > limit0 || limit1 === 0}/>
    </div>
    <div className='tlContainer'>
      <div className='tlstarContainer'>
        <img src='/images/star.png' alt='' className='tlstar'/>
        <img src='/images/star.png' alt='' className='tlstar'/>
        <img src='/images/star.png' alt='' className='tlstar'/>
      </div>
      <DurationSelector
        value={limit2}
        setValue={ (value) => inputChanged(value, 2) }
        hasError={limit2 > limit1 || limit2 > limit0 || limit2 === 0}/>
    </div>
  </div>);
};

TimeLimits.propTypes = {
  level: PropTypes.object,
  settings: PropTypes.object,

  changeLevelModified: PropTypes.func,
  setTimeLimits: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    level: state.level,
    settings: state.settings,
  };
};

export default connect(mapStateToProps, {
  changeLevelModified,
  setTimeLimits
})(TimeLimits);