import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useDropzone } from 'react-dropzone';

import {
  Modal
} from 'react-bulma-components';

import { importLevels } from 'reducers/levels';
import { validateLevelData } from 'utils';

const handleUploadedFiles = (acceptedFiles, connectedImportLevels, setErrorMessage) => {
  const reader = new FileReader();

  reader.onabort = () => console.log('file reading was aborted');
  reader.onerror = () => console.log('file reading has failed');
  reader.onload = () => {
    let levelData;
    try {
      levelData = JSON.parse( reader.result );
      if( !validateLevelData(levelData) ) {
        return setErrorMessage('Invalid file content');
      }
    } catch(err) {
      return setErrorMessage('Error while parsing JSON file');
    }

    connectedImportLevels(levelData);
  };

  acceptedFiles.forEach(file => {
    if(file.type === 'application/json') {
      reader.readAsText(file);
    } else {
      console.log(file.type);
      setErrorMessage(`Unable to process filetype: ${file.type}`);
    }
  });
};

const ImportModal = (props) => {
  const { show, setShow, settings } = props;
  const connectedImportLevels = props.importLevels;
  const [ message, setMessage ] = useState('');

  const setErrorMessage = (msg) => {
    setMessage(msg);
    window.setTimeout( () => {
      setMessage('');
    }, 5000);
  };

  const onDrop = useCallback(acceptedFiles => {
    handleUploadedFiles(acceptedFiles, connectedImportLevels, setErrorMessage);
  }, [connectedImportLevels]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  useEffect( () => {
    if(show && settings.importSuccess) {
      setShow(false);
    }
    // eslint-disable-next-line
  }, [ settings.importSuccess ] );

  const close = () => {
    setShow(false);
  };

  return (
    <Modal show={show} onClose={close}>
      <Modal.Card>
        <Modal.Card.Header onClose={close}>
          <Modal.Card.Title>
            Import levels
          </Modal.Card.Title>
        </Modal.Card.Header>
        <Modal.Card.Body>
          <div className="importLevelsDropzone" {...getRootProps()}>
            <input {...getInputProps()} />
            {
              isDragActive ?
                <p>Drop the files here...</p> :
                <p>Drag &apos;n&apos; drop leveldata.json here</p>
            }
          </div>
          { message === '' ? null : <div className="modalErrMessage">{message}</div> }
        </Modal.Card.Body>
      </Modal.Card>
    </Modal>
  );
};

ImportModal.propTypes = {
  show: PropTypes.bool,
  settings: PropTypes.object,
  setShow: PropTypes.func,
  importLevels: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    settings: state.settings,
  };
};

export default connect(mapStateToProps, {
  importLevels
})(ImportModal);