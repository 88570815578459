import { takeLatest, all } from 'redux-saga/effects';

import { doClassicLogin, doGapiLogin, doLogout } from './sagas/session';
import {
  doExportLevels, doImportLevels, doFetchLevels, doStoreLevel,
  doChangeLevel, doRemoveLevel, doFixLevelNumbers,
  doAlterLevelSelectedLevel, doStoreLevelOrder
} from './sagas/level';
import { doChangeDifficulty } from './sagas/common';
import { doSetNotification } from './sagas/notification';
import {
  doFetchUsers, doDeleteUser,
  doCreateUser, doChangeUserPassword
} from './sagas/users';

import {
  ACTION_CLASSIC_LOGIN,
  ACTION_GAPI_LOGIN,
  ACTION_LOGOUT,
  ACTION_STORE_LEVEL_ORDER,
  ACTION_EXPORT_LEVELS, ACTION_IMPORT_LEVELS,
  ACTION_FETCH_LEVELS, ACTION_CHANGE_DIFFICULTY,
  ACTION_NEXTPREV_LEVEL,
  ACTION_FIX_LEVEL_NUMBERS,
  ACTION_SET_NOTIFICATION, ACTION_STORE_LEVEL,
  ACTION_REMOVE_LEVEL, ACTION_CHANGE_LEVEL,
  ACTION_FETCH_USERS,
  ACTION_ADMIN_NEW_USER,
  ACTION_ADMIN_DEL_USER,
  ACTION_ADMIN_CHG_PASSWORD,
} from './constants';

function* actionWatcher() {
  yield takeLatest(ACTION_CLASSIC_LOGIN, doClassicLogin);
  yield takeLatest(ACTION_GAPI_LOGIN, doGapiLogin);
  yield takeLatest(ACTION_LOGOUT, doLogout);

  yield takeLatest(ACTION_CHANGE_DIFFICULTY, doChangeDifficulty);

  yield takeLatest(ACTION_SET_NOTIFICATION, doSetNotification);

  yield takeLatest(ACTION_STORE_LEVEL, doStoreLevel);
  yield takeLatest(ACTION_REMOVE_LEVEL, doRemoveLevel);
  yield takeLatest(ACTION_CHANGE_LEVEL, doChangeLevel);
  yield takeLatest(ACTION_NEXTPREV_LEVEL, doAlterLevelSelectedLevel);
  yield takeLatest(ACTION_FIX_LEVEL_NUMBERS, doFixLevelNumbers);

  yield takeLatest(ACTION_EXPORT_LEVELS, doExportLevels);
  yield takeLatest(ACTION_IMPORT_LEVELS, doImportLevels);
  yield takeLatest(ACTION_FETCH_LEVELS, doFetchLevels);
  yield takeLatest(ACTION_STORE_LEVEL_ORDER, doStoreLevelOrder);

  yield takeLatest(ACTION_FETCH_USERS, doFetchUsers);
  yield takeLatest(ACTION_ADMIN_NEW_USER, doCreateUser);
  yield takeLatest(ACTION_ADMIN_DEL_USER, doDeleteUser);
  yield takeLatest(ACTION_ADMIN_CHG_PASSWORD, doChangeUserPassword);
}

export default function* rootSaga() {
  yield all([
    actionWatcher()
  ]);
}