import { useState, useLayoutEffect } from 'react';

export const validateLevelData = (data) => {
  if(typeof(data) !== 'object' || !data.groups || !data.data) {
    return false;
  }

  if(!Array.isArray(data.groups) || !Array.isArray(data.data)) {
    return false;
  }

  return true;
};

export const stringToInt = (time) => {
  const times = time.split(':');
  return times.length === 2 ? parseInt(times[0])*60 + parseInt(times[1]) : 0;
};

export const intToString = (time) => {
  let sec = time % 60;
  sec = sec < 10 ? '0'+sec : sec;
  let mins = Math.floor(time/60);
  mins = mins < 10 ? '0'+mins: mins;

  return mins+':'+sec;
};

export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    const updateSize = () => {
      setSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
};