import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import {
  Button, Navbar
} from 'react-bulma-components';

import { doGoogleLogout } from 'utils/gauth';

import { doLogout } from 'reducers/session';
import { showNewUserModal } from 'reducers/modals';

import { LOGINTYPE_CLASSIC } from '../../constants';

const Header = (props) => {
  const [ open, setOpen ] = useState(false);

  const onEditorClick = (e) => {
    e.preventDefault();
    props.history.push('/editor');
  };

  const logoutEvent = async (e) => {
    e.preventDefault();
    if(props.session.loginType !== LOGINTYPE_CLASSIC) {
      await doGoogleLogout();
    }
    props.doLogout();
  };

  const onNewUserClick = (e) => {
    e.preventDefault();
    props.showNewUserModal();
  };

  return (
    <Navbar active={open}>
      <Navbar.Brand>
        <Navbar.Burger active={open ? true : undefined} onClick={ () => setOpen(!open) } />
      </Navbar.Brand>
      <Navbar.Menu>
        <Navbar.Item onClick={onNewUserClick}>
          New user
        </Navbar.Item>

        <Navbar.Container position="end">
          <Navbar.Item onClick={onEditorClick}>
            Back to editor
          </Navbar.Item>
          <Navbar.Item renderAs="div">
            <Button onClick={ logoutEvent }>Logout</Button>
          </Navbar.Item>
        </Navbar.Container>
      </Navbar.Menu>
    </Navbar>
  );
};

Header.propTypes = {
  history: PropTypes.object,
  session: PropTypes.object,

  doLogout: PropTypes.func,
  showNewUserModal: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    session: state.session
  };
};

export default withRouter(connect(mapStateToProps, {
  doLogout,
  showNewUserModal
})(Header));