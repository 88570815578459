import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPen } from '@fortawesome/free-solid-svg-icons';

import { deleteUser, createUser, changeUserPassword } from 'reducers/users';
import {
  showConfirmModal, hideConfirmModal, showEditUserModal
} from 'reducers/modals';

const UserItem = (props) => {
  const { user, session } = props;

  const onEditClick = (e) => {
    e.preventDefault();
    props.showEditUserModal();
  };

  const onDeleteClick = (e) => {
    e.preventDefault();

    props.showConfirmModal({
      title: 'Delete user?',
      message: 'Deleting user, will also delete users levels...',
      confirmText: 'Yes',
      confirmAction: () => {
        props.deleteUser(user.id);
        props.hideConfirmModal();
      }
    });
  };

  const delBtn = user.username !== session.username ? <FontAwesomeIcon
    className="delUserBtn"
    onClick={onDeleteClick}
    icon={faTrash}/> : null;

  const editBtn = user.username !== session.username ? <FontAwesomeIcon
    className="editUserBtn"
    onClick={onEditClick}
    icon={faPen}/> : null;

  return (<tr>
    <td>{editBtn} {delBtn}</td>
    <td>{user.gapiEmail}</td>
    <td>{user.username}</td>
    <td>{user.accountType}</td>
    <td>{user.levelCount}</td>
  </tr>);
};

UserItem.propTypes = {
  user: PropTypes.object,
  session: PropTypes.object,

  changeUserPassword: PropTypes.func,
  createUser: PropTypes.func,
  deleteUser: PropTypes.func,
  hideConfirmModal: PropTypes.func,
  showConfirmModal: PropTypes.func,
  showEditUserModal: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    session: state.session
  };
};

export default connect(mapStateToProps, {
  changeUserPassword,
  createUser,
  deleteUser,
  hideConfirmModal,
  showConfirmModal,
  showEditUserModal
})(UserItem);
