import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useDrag, useDrop } from 'react-dnd';

const LevelNumberButton = (props) => {
  const ref = useRef(null);

  const [, drop] = useDrop( () => ({
    accept: 'levelnumber',
    hover(item) {
      // Same item ?
      if(!ref.current || props.level.id === item.id) {
        return;
      }

      //
      props.switchLevelNumbers(props.level.id, item.id);
    }
  }) );

  const [{ isDragging }, drag] = useDrag( () => ({
    type: 'levelnumber',
    item: {
      type: 'levelnumber',
      id: props.level.id
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: () => {
      props.onLevelDragEnd();
    }
  }) );

  drag(drop(ref));

  const [ t0, t1, t2 ] = props.level.timeLimits;
  const hasError = props.level.timeLimits.some( lv => lv === 0 ) || t2 > t1 || t2 > t0 || t1 > t0;
  const classes = [
    'levelButton',
    hasError ? 'invalid' : 'valid',
    props.currentLevel ? 'selected' : null,
    props.disabled ? 'disabled' : undefined,
  ].join(' ');
  const opacity = isDragging ? 0 : 1;

  return (
    <div
      ref={ref}
      style={{ opacity }}
      className={classes}
      onClick={ () => !props.disabled && props.onLevelClicked(props.level.id) }
    >
      {props.level.number}
    </div>
  );
};

LevelNumberButton.propTypes = {
  currentLevel: PropTypes.bool,
  disabled: PropTypes.bool,
  level: PropTypes.object,

  onLevelClicked: PropTypes.func,
  onLevelDragEnd: PropTypes.func,
  switchLevelNumbers: PropTypes.func,
};

export default LevelNumberButton;