import {
  ACTION_CLASSIC_LOGIN, 
  ACTION_GAPI_LOGIN,
  ACTION_LOGOUT,
  ACTION_CLASSIC_LOGIN_SUCCESS,
  ACTION_LOGOUT_SUCCESS,
  ACTION_GAPI_LOGIN_SUCCESS,
  LOGINTYPE_CLASSIC,
  LOGINTYPE_GOOGLE
} from '../constants';

const initialState = {
  username: null,
  token: null,
  gapiEmail: null,
  groups: [],
  loginType: null
};

export const doLogin = (username, password) => ({
  type: ACTION_CLASSIC_LOGIN,
  username, password
});

export const doLogout = () => ({
  type: ACTION_LOGOUT
});

export const doGapiLogin = (email, token) => ({
  type: ACTION_GAPI_LOGIN,
  email, token
});

export const setUserData = (sessionData) => ({
  type: ACTION_CLASSIC_LOGIN_SUCCESS,
  ...sessionData
});

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case ACTION_LOGOUT_SUCCESS:
      return { ...initialState };
    case ACTION_CLASSIC_LOGIN_SUCCESS:
      return {
        ...state,
        username: action.username,
        groups: action.groups,
        token: action.token,
        loginType: LOGINTYPE_CLASSIC
      };
    case ACTION_GAPI_LOGIN_SUCCESS:
      return {
        ...state,
        gapiEmail: action.gapiEmail,
        groups: action.groups,
        token: action.token,
        loginType: LOGINTYPE_GOOGLE
      };
    default:
      return state;
  }
};

export default reducer;
