import cloneDeep from 'lodash/cloneDeep';

import { put,select } from 'redux-saga/effects';

import {
  ACTION_LEVELDATA_TO_STORE,
  ACTION_LEVELDATA_FROM_STORE,
  ACTION_SET_LEVELDATA,
  ACTION_SET_SAVING_CHANGES,
  ACTION_CHANGE_LEVEL,
  ACTION_CHANGE_MODIFIED,
  ACTION_RESET_LEVEL,
  ACTION_SET_NOTIFICATION,
  RESULT_EXPORT_SUCCESS,
  RESULT_EXPORT_FAILED,
  RESULT_IMPORT_SUCCESS,
  RESULT_IMPORT_FAILED,
  RESULT_FETCH_SUCCESS,
  RESULT_FETCH_FAILED,
  ACTION_STORE_LEVEL_ORDER,
  ACTION_RENUMBER_LEVELS,
  serverUri
} from '../constants';

import {
  getSession, getSettings,
  getLevel, getLevels
} from '../selectors';

export function* doExportLevels({ filetype }) {
  const session = yield select(getSession);
  const settings = yield select(getSettings);

  const config = {
    headers: { Authorization: `bearer ${session.token}` },
  };
  const endpoint = `${settings.difficulty}/export/${filetype}`;

  try {
    const levelDataRaw = yield fetch(`${serverUri}/level/${endpoint}`, config);
    let blob;
    if(filetype==='pdf') {
      const levelData = yield levelDataRaw.blob();
      blob = new Blob([ levelData ], { type: 'octet/stream' });
    } else {
      const levelData = yield levelDataRaw.json();
      blob = new Blob([ JSON.stringify(levelData) ], { type: 'octet/stream' });
    }

    const blobUrl = window.URL.createObjectURL(blob);
    const aObj = document.createElement('a');
    aObj.style.display = 'none';
    aObj.href = blobUrl;
    aObj.download = `leveldata.${filetype}`;
    document.body.appendChild(aObj);
    aObj.click();

    setTimeout( () => {
      aObj.parentNode.removeChild(aObj);
      window.URL.revokeObjectURL(blobUrl);
    });

    yield put({ type: RESULT_EXPORT_SUCCESS });
  } catch(error) {
    yield put({ type: RESULT_EXPORT_FAILED, error });
  }
}

export function* doFixLevelNumbers() {
  yield put({ type: ACTION_SET_SAVING_CHANGES, saving: true });

  yield put({ type: ACTION_RENUMBER_LEVELS });
  yield put({ type: ACTION_STORE_LEVEL_ORDER });
}

export function* doImportLevels({ levelData }) {
  const session = yield select(getSession);
  const settings = yield select(getSettings);
  const config = {
    headers: {
      Authorization: `bearer ${session.token}`,
      'Content-Type': 'application/json',
    }
  };

  try {
    yield fetch(`${serverUri}/level/import`, {
      ...config,
      body: JSON.stringify(levelData),
      method: 'POST',
    });
    const responseRaw = yield fetch(`${serverUri}/level/${settings.difficulty}`, config);
    const response = yield responseRaw.json();
    yield put({ type: RESULT_FETCH_SUCCESS, data: response });
    yield put({ type: ACTION_CHANGE_LEVEL, id: null, changeData: true });
    yield put({ type: RESULT_IMPORT_SUCCESS });
  } catch(error) {
    yield put({ type: RESULT_IMPORT_FAILED, error });
  }
}

export function* doFetchLevels({ difficulty }) {
  const session = yield select(getSession);
  const config = { headers: { Authorization: `bearer ${session.token}` } };

  try {
    const responseRaw = yield fetch(`${serverUri}/level/${difficulty}`, config);
    const response = yield responseRaw.json();
    yield put({ type: RESULT_FETCH_SUCCESS, data: response });
  } catch(error) {
    const errMsg = error.message;
    yield put({ type: ACTION_SET_NOTIFICATION,
      msgType: 'error',
      message: `Backend error: ${errMsg}`,
      duration: 5
    });
    yield put({ type: RESULT_FETCH_FAILED, error });
  }
}

// Move level selection one step forwards or backwards
export function* doAlterLevelSelectedLevel({ direction }) {
  if(direction !== 'next' && direction !== 'prev' ) {
    return;
  }

  const settings = yield select(getSettings);

  if(!settings.currentLevel) {
    return;
  }

  const levels = yield select(getLevels);
  const position = levels.findIndex( l => l.id === settings.currentLevel);

  let newLevel = null;

  if(direction === 'next') {
    if(position === (levels.length-1)) {
      return;
    } else {
      newLevel = levels[position+1].id;
    }
  }

  if(direction === 'prev') {
    if(position === 0) {
      return;
    } else {
      newLevel = levels[position-1].id;
    }
  }

  yield put({ type: ACTION_CHANGE_LEVEL, id: newLevel, changeData: true });
}

export function* doStoreLevelOrder() {
  yield put({ type: ACTION_SET_SAVING_CHANGES, saving: true });
  const levels = yield select(getLevels);
  const settings = yield select(getSettings);
  const session = yield select(getSession);

  const config = {
    headers: {
      Authorization: `bearer ${session.token}`,
      'Content-Type': 'application/json',
    }
  };

  const levelOrder = levels.map( (l) => ({ id: l.id, number: l.number }));

  try {
    yield fetch(
      `${serverUri}/level/reorder`,
      {
        ...config,
        body: JSON.stringify({ levelOrder, difficulty: settings.difficulty }),
        method: 'POST',
      }
    );
  } catch(error) {
    console.log(error);
  }

  // Fix level number to level reducer
  if(settings.currentLevel) {
    const levelState = yield select(getLevel);
    const levelData = levels.find( (l) => l.id === settings.currentLevel );

    yield put ({
      type: ACTION_SET_LEVELDATA,
      id: levelData.id,
      number: levelData.number,
      data: levelState.data,
      timeLimits: levelState.timeLimits
    });
  }

  //
  yield put({ type: ACTION_SET_SAVING_CHANGES, saving: false });
}

// Change to level number (x), or null to create new level
export function* doChangeLevel({ id, changeData }) {
  if(!changeData) {
    return;
  }

  const settings = yield select(getSettings);
  yield put({
    type: ACTION_CHANGE_MODIFIED,
    levelModified: false
  });

  // Reset leveldata
  if(!id) {
    yield put({ type: ACTION_RESET_LEVEL, difficulty: settings.difficulty });
    return;
  }

  // Try to fetch selected levels data
  const levels = yield select(getLevels);
  const levelData = levels.find( (l) => l.id === id );

  if(!levelData) {
    yield put({ type: ACTION_RESET_LEVEL, difficulty: settings.difficulty });
  } else {
    yield put ({
      type: ACTION_SET_LEVELDATA,
      id,
      number: levelData.number,
      data: levelData.data,
      timeLimits: levelData.timeLimits
    });
  }
}

export function* doRemoveLevel({ id }) {
  yield put({ type: ACTION_SET_SAVING_CHANGES, saving: true });

  const settings = yield select(getSettings);
  const session = yield select(getSession);
  const config = { headers: { Authorization: `bearer ${session.token}` } };

  try {
    yield fetch(`${serverUri}/level/${id}`, { ...config, method: 'DELETE' });

    yield put({ type: ACTION_LEVELDATA_FROM_STORE, id });
    yield put({ type: ACTION_CHANGE_MODIFIED, levelModified: false });
    yield put({ type: ACTION_CHANGE_LEVEL, id: null, changeData: false });
    yield put({ type: ACTION_RESET_LEVEL, difficulty: settings.difficulty });
  } catch(err) {
    console.log(err);
  }

  yield put({ type: ACTION_SET_SAVING_CHANGES, saving: false });
}

export function* doStoreLevel({ newLevel }) {
  yield put({ type: ACTION_SET_SAVING_CHANGES, saving: true });
  const level = yield select(getLevel);
  const levels = yield select(getLevels);
  const session = yield select(getSession);
  const settings = yield select(getSettings);

  const config = {
    headers: {
      Authorization: `bearer ${session.token}`,
      'Content-Type': 'application/json',
    }
  };

  // Assign new level number (if new level)
  let id = level.id;
  let number = level.number;

  if(level.number === -1 || newLevel) {
    number = levels
      .map( (l) => l.number )
      .reduce( (a,b) => Math.max(a,b) , 0);
    number++;
    id = '';
  }

  // Clean up leveldata before saving
  const cleanData = cloneDeep(level.data);
  level.data.forEach( (row, rowIdx) => {
    row.forEach( (col, colIdx) => {
      if(col === null) {
        return;
      }

      const { type, variant } = col;
      cleanData[rowIdx][colIdx] = { type, variant };
    });
  });

  // Store level to server (use cleaned version of data)
  try {
    const responseRaw = yield fetch(
      `${serverUri}/level`,
      {
        ...config,
        method: 'POST',
        body: JSON.stringify({
          id,
          number,
          difficulty: settings.difficulty,
          data: cleanData,
          timeLimits: level.timeLimits,
        })
      }
    );
    const response = yield responseRaw.json();

    // Redux store still saves the original level data
    yield put({ type: ACTION_LEVELDATA_TO_STORE, level: {
      ...level,
      id: response.id,
      number
    } });
    yield put({ type: ACTION_CHANGE_MODIFIED, levelModified: false });
    yield put({ type: ACTION_CHANGE_LEVEL, id: response.id, changeData: false });

    // Update current level in store (add id & number to it)
    if(newLevel)
    {
      yield put({
        type: ACTION_SET_LEVELDATA,
        id: response.id,
        number: number,
        data: level.data,
        timeLimits: level.timeLimits
      });
    }

  } catch(err) {
    console.log(err);
  }

  yield put({ type: ACTION_SET_SAVING_CHANGES, saving: false });
}