import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { LEVELSIZES } from '../../constants';
import GridSquare from './gridSquare';
import { setItem, moveItem } from 'reducers/level';
import { changeLevelModified } from 'reducers/settings';

const LevelGrid = (props) => {
  const LevelSize = LEVELSIZES[props.settings.difficulty];
  let grid = [];

  for(let row=0; row<LevelSize.height; row++) {
    let gridRow = [];

    for(let col=0; col<LevelSize.width; col++) {
      gridRow.push(<GridSquare
        position={ { row,col } }
        level={ props.level.data }
        setItem={ props.setItem }
        moveItem={ props.moveItem }
        changeLevelModified={ props.changeLevelModified }
        key={`gs_${col}_${row}`}/>);
    }

    grid.push(<div className="gridRow" key={row}>{gridRow}</div>);
  }

  const styles={
    border: props.settings.tutorialPhase === 2 || props.settings.tutorialPhase === 3 ? '2px dashed red' : undefined,
    minWidth: LevelSize.width*65
  };

  return (<div className="levelGrid" style={styles}>{grid}</div>);
};

LevelGrid.propTypes = {
  setItem: PropTypes.func,
  moveItem: PropTypes.func,
  changeLevelModified: PropTypes.func,

  difficulty: PropTypes.string,
  level: PropTypes.object,
  settings: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    level: state.level,
    settings: state.settings,
  };
};

export default connect(mapStateToProps, {
  setItem,
  moveItem,
  changeLevelModified
})(LevelGrid);
