import {
  ACTION_SET_NOTIFICATION
} from '../constants';

const initialState = {
  msgType: '',
  message: '',
  duration: 0
};

export const setNotification = (message, msgType, duration) => ({
  type: ACTION_SET_NOTIFICATION,
  message, msgType, duration
});

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case ACTION_SET_NOTIFICATION:
      return {
        msgType: action.msgType,
        message: action.message,
        duration: action.duration
      };
    default:
      return state;
  }
};

export default reducer;
