import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { fixLevelNumbers } from 'reducers/levels';
import { showConfirmModal, hideConfirmModal } from 'reducers/modals';

const FixLebelNumberButton = (props) => {
  const btnDisabled = props.levels.length === 0 || props.settings.savingChanges;

  const buttonClasses = [
    'sidebarButton', 'deleteLevelButton'
  ].join(' ');

  const handleBtnClick = (e) => {
    e.preventDefault();

    if(props.settings.levelModified) {
      props.showConfirmModal({
        title: 'Renumber levels?',
        message: 'By running this action, you will lose all unsaved changes. Do you want to continue?',
        confirmText: 'Yes',
        confirmAction: () => {
          props.fixLevelNumbers();
          props.hideConfirmModal();
        }
      });
      return;
    }

    props.fixLevelNumbers();
  };

  const styles={
    border: '2px dashed red',
    padding: '10px'
  };

  const btn = <button
    disabled={btnDisabled}
    className={buttonClasses}
    onClick={handleBtnClick}>Fix numbers</button>;

  if(props.settings.tutorialPhase === 5) {
    return (<span style={styles}>{btn}</span>);
  } else {
    return btn;
  }
};

FixLebelNumberButton.propTypes = {
  levels: PropTypes.array,
  settings: PropTypes.object,

  fixLevelNumbers: PropTypes.func,
  showConfirmModal: PropTypes.func,
  hideConfirmModal: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    levels: state.levels,
    settings: state.settings,
  };
};

export default connect(mapStateToProps, {
  fixLevelNumbers,
  showConfirmModal,
  hideConfirmModal
})(FixLebelNumberButton);

