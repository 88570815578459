import { put } from 'redux-saga/effects';

import {
  ACTION_FETCH_LEVELS,
  ACTION_RESET_LEVEL
} from '../constants';

export function* doChangeDifficulty({ difficulty }) {
  // Reset internal level structure (level size change causes stuff otherwise)
  yield put({ type: ACTION_RESET_LEVEL, difficulty: difficulty });

  // Load levels from server
  yield put({ type: ACTION_FETCH_LEVELS, difficulty });
}