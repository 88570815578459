import React from 'react';
import PropTypes from 'prop-types';
import { useDrag } from 'react-dnd';

const DraggableItem = ({ type, position, specs }) => {
  const [{ isDragging }, drag] = useDrag( () => ({
    type,
    item: {
      type,
      position,
      variant: specs.variant
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  }) );

  return(
    <div ref={drag} className="draggableItem" style={{
      opacity: isDragging ? 0.5 : 1,
    }}>
      <img src={`images/tiles/${specs.img}`} alt=""/>
    </div>
  );
};

DraggableItem.propTypes = {
  position: PropTypes.object,
  specs: PropTypes.object,
  type: PropTypes.string
};

export default DraggableItem;