import cloneDeep from 'lodash/cloneDeep';

import {
  ACTION_RESET_LEVEL,
  ACTION_SET_LEVEL_ITEM,
  ACTION_SET_LEVELDATA,
  ACTION_SET_TIMELIMITS,
  ACTION_REMOVE_LEVEL_ITEM,
  ACTION_MOVE_LEVEL_ITEM,
  LEVELSIZES,
} from '../constants';

export const resetLevel = () => ({
  type: ACTION_RESET_LEVEL
});

export const setItem = (position, item) => ({
  type: ACTION_SET_LEVEL_ITEM,
  position,
  item
});

export const removeItem = (item) => ({
  type: ACTION_REMOVE_LEVEL_ITEM,
  item
});

export const moveItem = (position, item) => ({
  type: ACTION_MOVE_LEVEL_ITEM,
  position,
  item
});

export const setTimeLimits = (timeLimits) => ({
  type: ACTION_SET_TIMELIMITS,
  timeLimits
});

const createLevel = (difficulty) => {
  const timeLimits = [ 0,0,0 ];
  const data = [];
  const LevelSize = LEVELSIZES[difficulty];

  for(let row=0; row<LevelSize.height; row++) {
    const gridRow = [];
    for(let col=0; col<LevelSize.width; col++) {
      gridRow.push(null);
    }
    data.push(gridRow);
  }

  return { data, timeLimits, id: '', number: -1 };
};

const setLevelItem = (state, position, item) => {
  const level = cloneDeep(state);
  level.data[position.row][position.col] = item;
  return level;
};

const removeLevelItem = (state, item) => {
  const level = cloneDeep(state);
  level.data[item.position.row][item.position.col] = null;
  return level;
};

const moveLevelItem = (state, position, item) => {
  const level = cloneDeep(state);
  level.data[item.position.row][item.position.col] = null;
  level.data[position.row][position.col] = item;
  return level;
};

const reducer = (state = createLevel('easy'), action) => {
  switch(action.type) {
    case ACTION_SET_LEVELDATA:
      return {
        id: action.id,
        number: action.number,
        data: cloneDeep(action.data),
        timeLimits: cloneDeep(action.timeLimits),
      };
    case ACTION_RESET_LEVEL:
      return createLevel(action.difficulty);
    case ACTION_SET_LEVEL_ITEM:
      return setLevelItem(state, action.position, action.item);
    case ACTION_REMOVE_LEVEL_ITEM:
      return removeLevelItem(state, action.item);
    case ACTION_MOVE_LEVEL_ITEM:
      return moveLevelItem(state, action.position, action.item);
    case ACTION_SET_TIMELIMITS:
      return { ...state, timeLimits: action.timeLimits };
    default:
      return state;
  }
};

export default reducer;
