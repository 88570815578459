import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { changeLevel } from 'reducers/settings';
import { showConfirmModal, hideConfirmModal } from 'reducers/modals';
import { endLevelDrag, switchLevelNumbers } from 'reducers/levels';

import LevelNumberButton from './levelNumberButton';

const LevelNumbers = (props) => {
  const onLevelClicked = (levelId) => {
    const nextLevel = levelId === props.settings.currentLevel ? null : levelId;

    if(props.settings.levelModified) {
      props.showConfirmModal({
        title: 'Change level?',
        message: 'If you change level now, you will lose all unsaved changes. Do you want to continue?',
        confirmText: 'Yes',
        confirmAction: () => {
          props.changeLevel( nextLevel, true );
          props.hideConfirmModal();
        }
      });
      return;
    }

    props.changeLevel( nextLevel, true );
  };

  return(<div className="levelButtonContainer">
    {props.levels.map( (level) => (
      <LevelNumberButton
        disabled={props.settings.savingChanges}
        key={level.id}
        level={level}
        onLevelClicked={onLevelClicked}
        onLevelDragEnd={props.endLevelDrag}
        switchLevelNumbers={props.switchLevelNumbers}
        currentLevel={props.settings.currentLevel === level.id}
      />
    ))}
  </div>);
};

LevelNumbers.propTypes = {
  levels: PropTypes.array,
  settings: PropTypes.object,
  confirmModal: PropTypes.object,

  changeLevel: PropTypes.func,
  hideConfirmModal: PropTypes.func,
  endLevelDrag: PropTypes.func,
  showConfirmModal: PropTypes.func,
  switchLevelNumbers: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    levels: state.levels,
    settings: state.settings,
    confirmModal: state.confirmModal
  };
};

export default connect(mapStateToProps, {
  changeLevel,
  hideConfirmModal,
  endLevelDrag,
  switchLevelNumbers,
  showConfirmModal,
})(LevelNumbers);
