import React, { useState, useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Section, Columns } from 'react-bulma-components';

import Notification from 'components/notification';

import ConfirmModal from 'components/editor/confirmModal';
import CreateLevelButton from 'components/editor/createLevelButton';
import DeleteLevelButton from 'components/editor/deleteLevelButton';
import DraggableItem from 'components/editor/draggableItem';
import FixLevelNumbersButton from 'components/editor/fixLevelNumberButton';
import Header from 'components/editor/header';
import ImportModal from 'components/editor/importModal';
import LevelGrid from 'components/editor/levelGrid';
import LevelNumbers from 'components/editor/levelNumbers';
import Trashcan from 'components/editor/trashcan';
import TimeLimits from 'components/editor/timeLimits';
import TutorialLayout from 'components/editor/tutorialLayout';

import { LevelObjects } from '../constants';
import { useWindowSize } from '../utils';

import { fetchLevels, storeLevel } from 'reducers/levels';
import { changeToPrevLevel, changeToNextLevel } from 'reducers/settings';

import '../sass/editor.scss';

const EditorView = (props) => {
  const [ showImportModal, setShowImportModal ] = useState(false);
  const [ initDone, setInitDone ] = useState(false);
  const [ winWidth ] = useWindowSize();

  const { settings, session } = props;

  // Initial leveldata fetching .. After session token is set
  useEffect( () => {
    if(session.token === null || initDone) {
      return;
    }
    setInitDone(true);
    props.fetchLevels(settings.difficulty);
    // eslint-disable-next-line
  }, [session]);

  //
  const onSaveLevelClick = () => {
    props.storeLevel(false);
  };

  const onCreateLevelClick = () => {
    props.storeLevel(true);
  };

  const handleKeyDown = (event) => {
    const charCode = String.fromCharCode(event.which).toLowerCase();

    // Handle save shortcut (CTRL+s)
    if(event.ctrlKey && charCode === 's') {
      event.preventDefault();
      props.storeLevel(settings.currentLevel ? true : false);
    }

    // Change level with arrow keys (if no changes are done to current level)
    if(event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      if(!props.settings.levelModified && props.settings.currentLevel) {
        if(event.key === 'ArrowLeft') {
          props.changeToPrevLevel();
        } else {
          props.changeToNextLevel();
        }
      }
    }

  };

  const isAdmin = session.groups && session.groups.some( group => group.isAdmin === true );

  const styles1={
    border: props.settings.tutorialPhase === 2 ? '2px dashed red' : undefined
  };

  const styles2={
    border: props.settings.tutorialPhase === 4 ? '2px dashed red' : undefined
  };

  //
  return (
    <DndProvider backend={HTML5Backend}>
      <Header
        tabIndex="0"
        onKeyDown={handleKeyDown}
        showImportModal={setShowImportModal}
        isAdmin={isAdmin} />
      <Section tabIndex="0" onKeyDown={handleKeyDown}>
        <Columns className="editorContainer">
          <Columns.Column>
            <div style={styles2}>
              <LevelNumbers/>
              <CreateLevelButton
                onSaveLevelClick={onSaveLevelClick}
                onCreateLevelClick={onCreateLevelClick}
                disabled={props.settings.savingChanges}
              />
              <div className="sidebarButtonRow">
                <DeleteLevelButton/>
                <FixLevelNumbersButton/>
              </div>
            </div>
            { winWidth > 1250 ? null : <TimeLimits/> }
          </Columns.Column>
          <Columns.Column size={'half'} className="editorCenterColumn">
            <LevelGrid/>
            <Notification className="editorNotification"/>
          </Columns.Column>
          <Columns.Column className="editorRightBlock">
            { winWidth <= 1250 ? null : <TimeLimits/> }

            <div style={styles1}>
              <h1>Level components:</h1>
              { LevelObjects.monster.map( obj => <DraggableItem
                type="monster" position={ null } specs={obj} key={`monster_${obj.variant}`}/> )}

              { LevelObjects.wall.map( obj => <DraggableItem
                type="wall"  position={ null } specs={obj} key={`wall_${obj.variant}`}/> )}
            </div>

            <Trashcan/>
          </Columns.Column>
        </Columns>
      </Section>
      <ImportModal show={showImportModal} setShow={setShowImportModal}/>
      <ConfirmModal/>
      <TutorialLayout/>
    </DndProvider>
  );
};

EditorView.propTypes = {
  settings: PropTypes.object,
  session: PropTypes.object,

  changeToPrevLevel: PropTypes.func,
  changeToNextLevel: PropTypes.func,
  fetchLevels: PropTypes.func,
  storeLevel: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    settings: state.settings,
    session: state.session
  };
};

export default connect(mapStateToProps, {
  fetchLevels,
  storeLevel,
  changeToPrevLevel,
  changeToNextLevel,
})(EditorView);
