import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import UserItem from './userItem';
import { fetchUsers } from 'reducers/users';

const UserList = (props) => {

  //
  useEffect( () => {
    props.fetchUsers();
    // eslint-disable-next-line
  }, []);

  return (<div>
    <table className="table">
      <thead>
        <tr>
          <th className="tblAdmBtnFld">&nbsp;</th>
          <th className="tblGapiEmailFld">GApi email</th>
          <th className="tblUsernameFld">Username</th>
          <th className="tblTypeFld">Account type</th>
          <th className="tblLevelCnt">Levels</th>
        </tr>
      </thead>
      <tbody>
        { props.users.map( (user) => <UserItem key={user.id} user={user}/>) }
      </tbody>
    </table>
  </div>);
};

UserList.propTypes = {
  fetchUsers: PropTypes.func,

  users: PropTypes.array
};

const mapStateToProps = (state) => {
  return {
    users: state.users,
  };
};

export default connect(mapStateToProps, {
  fetchUsers
})(UserList);
