import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  Button, Container,
  Columns, Form, Icon
} from 'react-bulma-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';

import Notification from '../components/notification';
import { doLogin, doGapiLogin } from '../reducers/session';
import { doGoogleLogin } from '../utils/gauth';

import '../sass/login.scss';

const LoginView = (props) => {
  const [ username, setUsername ] = useState('');
  const [ password, setPassword ] = useState('');

  const triggerLogin = (e) => {
    e.preventDefault();
    props.doLogin( username, password );
    setUsername('');
    setPassword('');
  };

  const googleLogin = (e) => {
    e.preventDefault();
    doGoogleLogin(props.doGapiLogin);
  };

  return (
    <Container>
      <Columns centered={true} vCentered={true} className="loginViewContainer">
        <Columns.Column size={'one-quarter'} className="loginViewBox">

          <Form.Field>
            <Form.Label>Username:</Form.Label>
            <Form.Control iconLeft={true}>
              <Form.Input
                value={username}
                placeholder="username"
                onChange={ (e) => setUsername(e.target.value) } />
              <Icon size="small" align="left">
                <FontAwesomeIcon icon={faUser} />
              </Icon>
            </Form.Control>
          </Form.Field>

          <Form.Field>
            <Form.Label>Password:</Form.Label>
            <Form.Control iconLeft={true}>
              <Form.Input
                value={password}
                placeholder="password"
                type="password"
                onChange={ (e) => setPassword(e.target.value) }/>
              <Icon size="small" align="left">
                <FontAwesomeIcon icon={faLock} />
              </Icon>
            </Form.Control>
          </Form.Field>

          <Notification/>

          <Form.Field>
            <Form.Control>
              <Button
                color="primary"
                onClick={triggerLogin}>Login</Button>
              &nbsp;&nbsp;
              <Button
                color="info"
                onClick={googleLogin}><FontAwesomeIcon icon={faGoogle} /> &nbsp; signin</Button>
            </Form.Control>
          </Form.Field>

        </Columns.Column>
      </Columns>
    </Container>
  );
};

LoginView.propTypes = {
  doLogin: PropTypes.func,
  doGapiLogin: PropTypes.func
};

export default connect(null, { 
  doLogin, doGapiLogin 
})(LoginView);