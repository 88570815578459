import cloneDeep from 'lodash/cloneDeep';

import {
  ACTION_STORE_LEVEL_ORDER,
  ACTION_EXPORT_LEVELS,
  ACTION_FETCH_LEVELS,
  ACTION_RENUMBER_LEVELS,
  ACTION_FIX_LEVEL_NUMBERS,
  ACTION_IMPORT_LEVELS,
  ACTION_LEVELDATA_TO_STORE,
  ACTION_LEVELDATA_FROM_STORE,
  ACTION_REMOVE_LEVEL,
  ACTION_STORE_LEVEL,
  ACTION_SWITCH_LEVEL_NUMBERS,
  RESULT_FETCH_SUCCESS,
} from '../constants';

export const endLevelDrag = () => ({
  type: ACTION_STORE_LEVEL_ORDER
});

export const fetchLevels = (difficulty) => ({
  type: ACTION_FETCH_LEVELS, difficulty
});

export const importLevels = (levelData) => ({
  type: ACTION_IMPORT_LEVELS, levelData
});

export const switchLevelNumbers = (from, to) => ({
  type: ACTION_SWITCH_LEVEL_NUMBERS, from, to
});

export const storeLevel = (newLevel) => ({
  type: ACTION_STORE_LEVEL,
  newLevel
});

export const removeLevel = (id) => ({
  type: ACTION_REMOVE_LEVEL, id
});

export const exportLevelsAsJson = () => ({
  type: ACTION_EXPORT_LEVELS, filetype: 'json'
});

export const exportLevelsAsPdf = () => ({
  type: ACTION_EXPORT_LEVELS, filetype: 'pdf'
});

export const fixLevelNumbers = () => ({
  type: ACTION_FIX_LEVEL_NUMBERS
});

const renumberLevels = (state) => {
  let levels = cloneDeep(state);
  let idx = 1;
  for(let level of levels) {
    level.number = idx;
    idx++;
  }
  return levels.sort( (a,b) => a.number - b.number );
};

const storeLevelToState = (state, newLevel) => {
  let levels = cloneDeep(state);
  levels = levels.filter( l => l.id !== newLevel.id );
  levels.push(newLevel);
  return levels.sort( (a,b) => a.number - b.number );
};

const removeLevelFromState = (state, id) => {
  let levels = cloneDeep(state);
  return levels.filter( level => level.id !== id );
};

const switchLevelNumbersInState = (state, from, to) => {
  let levels = cloneDeep(state);
  const fromLevel = levels.find( l => l.id === from );
  const toLevel = levels.find( l => l.id === to );

  levels = levels.map( l => {
    if(l.id !== from && l.id !== to) {
      return l;
    }
    return l.id === from ? toLevel : fromLevel;
  });

  let idx = 1;
  for(const level of levels) {
    level.number = idx;
    idx++;
  }

  return levels;
};

const reducer = (state = [], action) => {
  switch(action.type) {
    case ACTION_LEVELDATA_TO_STORE:
      return storeLevelToState(state, action.level);
    case ACTION_LEVELDATA_FROM_STORE:
      return removeLevelFromState(state, action.id);
    case ACTION_RENUMBER_LEVELS:
      return renumberLevels(state);
    case ACTION_SWITCH_LEVEL_NUMBERS:
      return switchLevelNumbersInState(state, action.from, action.to);
    case RESULT_FETCH_SUCCESS:
      return action.data;
    default:
      return state;
  }
};

export default reducer;
