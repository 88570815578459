import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Route, BrowserRouter, Redirect } from 'react-router-dom';

import LoginView from './views/login';
import EditorView from './views/editor';
import AdminView from './views/admin';

import { setUserData, doGapiLogin } from './reducers/session';
import { initGauth } from './utils/gauth';

import './sass/app.scss';

let loggedIn;

const App = (props) => {
  loggedIn = props.session.token && props.session.token.length > 10 ? true : false;

  const startGapiInit = useCallback(() => {
    initGauth(props.doGapiLogin, loggedIn);
  }, [props.doGapiLogin]);

  useEffect(() => {
    //
    const appSession = window.localStorage.getItem('appSession');
    if (appSession) {
      props.setUserData(JSON.parse(appSession));
    }

    window.addEventListener('gapi-loaded', startGapiInit);
    return () => window.removeEventListener('gapi-loaded', startGapiInit);
    // eslint-disable-next-line
  }, []);

  return (
    <BrowserRouter>
      <Route exact path="/" render={() => (loggedIn ? <Redirect to="/editor" /> : <LoginView />)} />
      <Route path="/editor" render={() => (!loggedIn ? <Redirect to="/" /> : <EditorView />)} />
      <Route path="/admin" render={() => (!loggedIn ? <Redirect to="/" /> : <AdminView />)} />
    </BrowserRouter>
  );
};

App.propTypes = {
  session: PropTypes.object,

  doGapiLogin: PropTypes.func,
  setUserData: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    session: state.session
  };
};

export default connect(mapStateToProps, {
  doGapiLogin,
  setUserData
})(App);
