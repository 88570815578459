import {
  ACTION_CHANGE_DIFFICULTY,
  ACTION_CHANGE_LEVEL,
  ACTION_NEXTPREV_LEVEL,
  ACTION_CHANGE_MODIFIED,
  ACTION_SET_SAVING_CHANGES,
  ACTION_SET_TUTORIAL_PHASE,
  ACTION_RESET_SETTINGS,

  ACTION_IMPORT_LEVELS,
  RESULT_IMPORT_SUCCESS,
  RESULT_IMPORT_FAILED,
} from '../constants';

const initialState = {
  difficulty: 'easy',
  currentLevel: null,
  levelModified: false,
  importSuccess: null,
  tutorialPhase: null,
  savingChanges: false,
};

export const changeDifficulty = (difficulty) => ({
  type: ACTION_CHANGE_DIFFICULTY,
  difficulty
});

export const setTutorialPhase = (phase) => ({
  type: ACTION_SET_TUTORIAL_PHASE,
  phase
});

export const setSavingChanges = (saving) => ({
  type: ACTION_SET_SAVING_CHANGES,
  saving
});

export const changeLevel = (id, changeData) => ({
  type: ACTION_CHANGE_LEVEL,
  id,
  changeData
});

export const changeToPrevLevel = () => ({
  type: ACTION_NEXTPREV_LEVEL,
  direction: 'prev'
});

export const changeToNextLevel = () => ({
  type: ACTION_NEXTPREV_LEVEL,
  direction: 'next'
});

export const changeLevelModified = (levelModified) => ({
  type: ACTION_CHANGE_MODIFIED,
  levelModified
});

export const resetSettings = () => ({
  type: ACTION_RESET_SETTINGS
});

const reducer = (state = initialState, action) => {
  switch(action.type) {

    case RESULT_IMPORT_SUCCESS:
      return {
        ...state,
        importSuccess: true
      };
    case RESULT_IMPORT_FAILED:
      return {
        ...state,
        importSuccess: false
      };
    case ACTION_SET_SAVING_CHANGES:
      return {
        ...state,
        savingChanges: action.saving
      };
    case ACTION_IMPORT_LEVELS:
      return {
        ...state,
        importSuccess: null
      };
    case ACTION_SET_TUTORIAL_PHASE:
      return {
        ...state,
        tutorialPhase: action.phase
      };
    case ACTION_CHANGE_MODIFIED:
      return {
        ...state,
        levelModified: action.levelModified
      };
    case ACTION_CHANGE_DIFFICULTY:
      return {
        ...state,
        difficulty: action.difficulty
      };
    case ACTION_CHANGE_LEVEL:
      return {
        ...state,
        currentLevel: action.id
      };
    case ACTION_RESET_SETTINGS:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default reducer;
