import { delay, put } from 'redux-saga/effects';

import {
  ACTION_SET_NOTIFICATION
} from '../constants';

export function* doSetNotification({ duration }) {
  if(duration === 0) {
    return;
  }

  yield delay(duration*1000);
  yield put({ type: ACTION_SET_NOTIFICATION,
    msgType: '',
    message: '',
    duration: 0
  });
}