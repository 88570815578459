import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevToolsDevelopmentOnly } from '@redux-devtools/extension';
import createSagaMiddleware from 'redux-saga';

import rootSaga from './rootSaga';
import sessionReducer from './reducers/session';
import levelReducer from './reducers/level';
import levelsReducer from './reducers/levels';
import settingsReducer from './reducers/settings';
import userReducer from './reducers/users';
import notificationReducer from './reducers/notification';
import modalReducer from './reducers/modals';

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = composeWithDevToolsDevelopmentOnly({});

const reducer = combineReducers({
  session: sessionReducer,
  level: levelReducer,
  levels: levelsReducer,
  settings: settingsReducer,
  notification: notificationReducer,
  modals: modalReducer,
  users: userReducer
});

const store = createStore(
  reducer,
  composeEnhancers(
    applyMiddleware(sagaMiddleware)
  )
);
sagaMiddleware.run(rootSaga);

export default store;