import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { showConfirmModal, hideConfirmModal } from 'reducers/modals';
import { removeLevel } from 'reducers/levels';

const DeleteLevelButton = (props) => {

  const { settings } = props;

  const inEditMode = settings.currentLevel !== null;

  const onDeleteClick = (e) => {
    e.preventDefault();
    if(!inEditMode) {
      return;
    }

    props.showConfirmModal({
      title: 'Delete level?',
      message: 'Do you really want to delete level?',
      confirmText: 'Yes',
      confirmAction: () => {
        props.removeLevel(settings.currentLevel);
        props.hideConfirmModal();
      }
    });

  };

  const buttonClasses = [
    'sidebarButton', 'deleteLevelButton'
  ].join(' ');

  return (<button
    disabled={!inEditMode || props.settings.savingChanges}
    onClick={onDeleteClick}
    className={buttonClasses}>Delete level
  </button>);
};

DeleteLevelButton.propTypes = {
  settings: PropTypes.object,

  hideConfirmModal: PropTypes.func,
  removeLevel: PropTypes.func,
  showConfirmModal: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    settings: state.settings
  };
};

export default connect(mapStateToProps, {
  hideConfirmModal,
  showConfirmModal,
  removeLevel
})(DeleteLevelButton);
