import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Button } from 'react-bulma-components';

import { setTutorialPhase } from 'reducers/settings';

const NUM_OF_PHASES = 6;

const TutorialLayout = (props) => {
  if(props.settings.tutorialPhase === null) {
    return null;
  }

  const advanceTutorial = (e) => {
    e.preventDefault();
    if(props.settings.tutorialPhase < NUM_OF_PHASES) {
      props.setTutorialPhase( props.settings.tutorialPhase+1 );
    }
  };

  const closeTutorial = (e) => {
    e.preventDefault();
    props.setTutorialPhase(null);
  };

  const tutorialTexts = [
    'Set level timelimits here. Time in "one star" field is the maximum time for level.',
    'Create level by dragging elements from components view to level grid.',
    'Remove items from level grid by dragging them to trash',
    'Create new level with "Save as new level" -button. Switch levels by clicking level number.',
    'This button fixes level numbering (eg. 1,2,4 -> 1,2,3)',
    'You can also save changes with CTRL+S',
  ];

  return (<div className="tutorialLayout">
    <div className="container">

      { tutorialTexts[props.settings.tutorialPhase-1] }

      <div className="buttonArea">
        <Button onClick={closeTutorial}>Close</Button>
        <Button onClick={advanceTutorial} disabled={props.settings.tutorialPhase>=NUM_OF_PHASES}>Next</Button>
      </div>
    </div>
  </div>);
};

TutorialLayout.propTypes = {
  settings: PropTypes.object,
  setTutorialPhase: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    settings: state.settings
  };
};

export default connect(mapStateToProps, {
  setTutorialPhase
})(TutorialLayout);
