let auth2 = null;
let gUser = null;
let gUserProfile = null;

const getUserdata = ( doGapiLogin ) => {
  if(auth2.isSignedIn.get()) {
    gUser = auth2.currentUser.get();
    gUserProfile = gUser.getBasicProfile();
    doGapiLogin(
      gUserProfile.getEmail(),
      gUser.getAuthResponse().id_token
    );
  }
};

export const doGoogleLogin = async ( doGapiLogin ) => {
  const gapi = window['gapi'];

  if(process.env.REACT_APP_GOOGLE_AUTH_ID) {
    await gapi.auth2.getAuthInstance().signIn();
    getUserdata(doGapiLogin);
  } else {
    console.log('Unable to login... Missing gapi token');
  }
};

export const doGoogleLogout = async () => {
  const gapi = window['gapi'];

  if(process.env.REACT_APP_GOOGLE_AUTH_ID) {
    await gapi.auth2.getAuthInstance().signOut();
  }
};

export const initGauth = ( doGapiLogin, loggedIn ) => {
  const gapi = window['gapi'];

  gapi.load('auth2', async () => {
    if(!loggedIn && process.env.REACT_APP_GOOGLE_AUTH_ID) {
      auth2 = await gapi.auth2.init({
        client_id: process.env.REACT_APP_GOOGLE_AUTH_ID,
        scope: 'https://www.googleapis.com/auth/userinfo.email'
      });
      getUserdata(doGapiLogin);
    }
  });

};