import { put,select } from 'redux-saga/effects';

import {
  ACTION_LOGIN_FAILED,
  ACTION_CLASSIC_LOGIN_SUCCESS,
  ACTION_GAPI_LOGIN_SUCCESS,
  ACTION_LOGOUT_SUCCESS,
  ACTION_LOGOUT_FAILED,
  ACTION_SET_NOTIFICATION,
  ACTION_RESET_SETTINGS,
  ACTION_RESET_LEVEL,
  serverUri
} from '../constants';

import { getSession } from '../selectors';

export function* doClassicLogin({ username, password }) {
  try {
    const responseRaw = yield fetch(`${serverUri}/login`, {
      body: JSON.stringify({ username, password }),
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    });
    const response = yield responseRaw.json();

    const sessData = {
      username: response.username,
      groups: response.groups,
      token: response.token
    };

    window.localStorage.setItem('appSession', JSON.stringify(sessData));
    yield put({ type: ACTION_CLASSIC_LOGIN_SUCCESS, ...sessData });

  } catch(error) {
    yield put({ type: ACTION_LOGIN_FAILED, error });
    if( error.response && error.response.status === 401 ) {
      yield put({ type: ACTION_SET_NOTIFICATION,
        msgType: 'error',
        message: 'Login failed',
        duration: 5
      });
    } else {
      yield put({ type: ACTION_SET_NOTIFICATION,
        msgType: 'error',
        message: 'Backend not responding',
        duration: 5
      });
    }
  }
}

export function* doGapiLogin({ email, token }) {
  try {
    const responseRaw = yield fetch(`${serverUri}/gapilogin`, {
      body: JSON.stringify({ email, token }),
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    });
    const response = yield responseRaw.json();

    const sessData = {
      gapiEmail: response.gapiEmail,
      groups: response.groups,
      token: response.token
    };

    yield put({ type: ACTION_GAPI_LOGIN_SUCCESS, ...sessData });

  } catch(error) {
    yield put({ type: ACTION_LOGIN_FAILED, error });
    if( error.response && error.response.status === 401 ) {
      yield put({ type: ACTION_SET_NOTIFICATION,
        msgType: 'error',
        message: 'Login via google failed',
        duration: 5
      });
    } else {
      yield put({ type: ACTION_SET_NOTIFICATION,
        msgType: 'error',
        message: 'Backend not responding',
        duration: 5
      });
    }
  }
}

export function* doLogout() {
  const session = yield select(getSession);
  const config = { headers: { Authorization: `bearer ${session.token}` } };

  try {
    const response = yield fetch(`${serverUri}/logout`, { ...config, method: 'POST' });
    window.localStorage.removeItem('appSession');
    yield put({ type: ACTION_RESET_SETTINGS });
    yield put({ type: ACTION_RESET_LEVEL, difficulty: 'easy' });
    yield put({ type: ACTION_LOGOUT_SUCCESS, data: response.data });
  } catch(error) {
    if(error.response && error.response.status === 401) {
      // This means that current token is not valid, so technically user is already logged out
      window.localStorage.removeItem('appSession');
      yield put({ type: ACTION_LOGOUT_SUCCESS, data: error.response.data });
    } else {
      yield put({ type: ACTION_LOGOUT_FAILED, error });
    }
  }
}