import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const CreateLevelButton = (props) => {

  const onSaveLevelClick = (e) => {
    e.preventDefault();
    props.onSaveLevelClick();
  };

  const onCreateLevelClick = (e) => {
    e.preventDefault();
    props.onCreateLevelClick();
  };

  const inEditMode = props.settings.currentLevel !== null;

  const button1Classes = [
    'sidebarButton',
    props.settings.levelModified && inEditMode ? 'levelModified' : 'levelNotModified'
  ].join(' ');

  const button2Classes = 'sidebarButton levelNotModified';

  const styles1 = {
    border: props.settings.tutorialPhase === 6 ? '2px dashed red' : undefined,
    padding: props.settings.tutorialPhase === 6 ? '10px' : undefined,
    display: 'inline'
  };

  return (<div className="sidebarButtonRow">
    <div style={styles1}>
      <button
        onClick={onSaveLevelClick}
        disabled={!inEditMode || props.settings.savingChanges}
        className={button1Classes}>Save changes</button>
    </div>
    <button
      onClick={onCreateLevelClick}
      disabled={props.settings.savingChanges}
      className={button2Classes}>Save as new level</button>
  </div>);
};

CreateLevelButton.propTypes = {
  onCreateLevelClick: PropTypes.func,
  onSaveLevelClick: PropTypes.func,
  settings: PropTypes.object
};

const mapStateToProps = (state) => {
  return {
    settings: state.settings
  };
};

export default connect(mapStateToProps, null)(CreateLevelButton);
