import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useDrop } from 'react-dnd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

import { changeLevelModified } from 'reducers/settings';
import { removeItem } from 'reducers/level';

const Trashcan = (props) => {

  const [{ isOver, canDrop }, drop] = useDrop( () => ({
    accept: [ 'wall', 'monster' ],
    canDrop: (item) => {
      return item.position !== null;
    },
    drop: (item) => {
      props.removeItem(item);
      props.changeLevelModified(true);
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    })
  }) );

  const classes = [
    'trashcan',
    isOver ? (canDrop ? 'canDrop' : 'cannotDrop') : ''
  ].join(' ');

  const styles={
    border: props.settings.tutorialPhase === 3 ? '2px dashed red' : undefined
  };

  return (<div ref={drop}className={ classes } style={styles}><FontAwesomeIcon icon={faTrash}/></div>);
};

Trashcan.propTypes = {
  changeLevelModified: PropTypes.func,
  removeItem: PropTypes.func,

  settings: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    settings: state.settings,
  };
};

export default connect(mapStateToProps, {
  changeLevelModified,
  removeItem
})(Trashcan);