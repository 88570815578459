export const serverUri = process.env.NODE_ENV === 'production' ? '/api' : 'http://192.168.1.109:8080/api';

export const ACTION_CLASSIC_LOGIN = 'CLASSIC-LOGIN';
export const ACTION_GAPI_LOGIN = 'GAPI-LOGIN';
export const ACTION_LOGIN_FAILED = 'LOGIN-FAILED';
export const ACTION_CLASSIC_LOGIN_SUCCESS = 'CLASSIC-LOGIN-SUCCESS';
export const ACTION_GAPI_LOGIN_SUCCESS = 'GAPI-LOGIN-SUCCESS';
export const ACTION_LOGOUT = 'LOGOUT';
export const ACTION_RESET_SETTINGS = 'RESET-SETTINGS';
export const ACTION_LOGOUT_FAILED = 'LOGOUT-FAILED';
export const ACTION_RENUMBER_LEVELS = 'RENUMBER-LEVELS';
export const ACTION_LOGOUT_SUCCESS = 'LOGOUT-SUCCESS';
export const ACTION_CHANGE_DIFFICULTY = 'CHANGE-DIFFICULTY';
export const ACTION_CHANGE_MODIFIED = 'CHANGE-MODIFIED';
export const ACTION_CHANGE_LEVEL = 'CHANGE-LEVEL';
export const ACTION_NEXTPREV_LEVEL = 'NEXT-OR-PREV-LEVEL';
export const ACTION_REMOVE_LEVEL = 'REMOVE-LEVEL';
export const ACTION_EXPORT_LEVELS = 'EXPORT-LEVELS';
export const ACTION_SET_SAVING_CHANGES = 'SET-SAVING-CHANGES';
export const ACTION_STORE_LEVEL_ORDER = 'STORE-LEVEL-ORDER';
export const ACTION_IMPORT_LEVELS = 'IMPORT-LEVELS';
export const ACTION_FETCH_LEVELS = 'LOAD-LEVELS';
export const ACTION_FETCH_USERS = 'LOAD-USERS';
export const ACTION_FIX_LEVEL_NUMBERS = 'FIX-LEVEL-NUMBERS';
export const ACTION_SWITCH_LEVEL_NUMBERS = 'SWITCH-LEVEL-NUMBERS';
export const ACTION_RESET_LEVEL = 'RESET-LEVEL';
export const ACTION_SET_LEVEL_ITEM = 'SET-LEVEL-ITEM';
export const ACTION_SET_TIMELIMITS = 'SET-TIMELIMITS';
export const ACTION_SET_TUTORIAL_PHASE = 'SET-TUTORIAL-PHASE';
export const ACTION_REMOVE_LEVEL_ITEM = 'REMOVE-LEVEL-ITEM';
export const ACTION_MOVE_LEVEL_ITEM = 'MOVE-LEVEL-ITEM';
export const ACTION_SET_NOTIFICATION = 'SET-NOTICATION';
export const ACTION_STORE_LEVEL = 'STORE-LEVEL';
export const ACTION_SET_LEVELDATA = 'SET-LEVELDATA';
export const ACTION_ADMIN_CHG_PASSWORD = 'ADM-CHG-PASSWD';
export const ACTION_ADMIN_DEL_USER = 'ADM-DEL-USER';
export const ACTION_ADMIN_NEW_USER = 'ADM-NEW-USER';
export const ACTION_LEVELDATA_TO_STORE = 'LEVELDATA-TO_STORE';
export const ACTION_LEVELDATA_FROM_STORE = 'LEVELDATA-FROM-STORE';
export const ACTION_SHOW_CONFIRM_MODAL = 'SHOW-CONFIRM-MODAL';
export const ACTION_HIDE_CONFIRM_MODAL = 'HIDE-CONFIRM-MODAL';
export const ACTION_SHOW_NEW_USER_MODAL = 'SHOW-NEW-USER-MODAL';
export const ACTION_SHOW_EDIT_USER_MODAL = 'SHOW-EDIT-USER-MODAL';
export const ACTION_HIDE_USER_MODAL = 'HIDE-USER-MODAL';

export const RESULT_EXPORT_SUCCESS = 'EXPORT-SUCCESS';
export const RESULT_ADM_NEW_SUCCESS = 'ADM-NEW-SUCCESS';
export const RESULT_ADM_PWD_SUCCESS = 'ADM-PWD-SUCCESS';
export const RESULT_ADM_DEL_SUCCESS = 'ADM-DEL-SUCCESS';
export const RESULT_EXPORT_FAILED = 'EXPORT-FAILED';
export const RESULT_IMPORT_SUCCESS = 'IMPORT-SUCCESS';
export const RESULT_IMPORT_FAILED = 'IMPORT-FAILED';
export const RESULT_FETCH_SUCCESS = 'FETCH-SUCCESS';
export const RESULT_USER_FETCH_SUCCESS = 'USER-FETCH-SUCCESS';
export const RESULT_FETCH_FAILED = 'FETCH-FAILED';

export const LOGINTYPE_CLASSIC = 'classic';
export const LOGINTYPE_GOOGLE = 'google';

export const LEVELSIZES = {
  easy: {
    width: 8,
    height: 5
  },
  medium: {
    width: 9,
    height: 6
  },
  hard: {
    width: 10,
    height: 7
  }
};

export const LevelObjects = {
  monster: [
    {
      variant: 'blue',
      img: 'monster_blue.png'
    },
    {
      variant: 'gray',
      img: 'monster_gray.png'
    },
    {
      variant: 'green',
      img: 'monster_green.png'
    },
    {
      variant: 'pink',
      img: 'monster_pink.png'
    },
    {
      variant: 'red',
      img: 'monster_red.png'
    }
  ],
  wall: [
    {
      variant: '1a',
      img: 'wall_1a.png'
    },
    {
      variant: '1b',
      img: 'wall_1b.png'
    },
    {
      variant: '2a',
      img: 'wall_2a.png'
    },
    {
      variant: '2b',
      img: 'wall_2b.png'
    },
    {
      variant: '2c',
      img: 'wall_2c.png'
    },
    {
      variant: '2d',
      img: 'wall_2d.png'
    },
    {
      variant: '3a',
      img: 'wall_3a.png'
    },
    {
      variant: '3b',
      img: 'wall_3b.png'
    },
    {
      variant: '4a',
      img: 'wall_4a.png'
    },
    {
      variant: '4b',
      img: 'wall_4b.png'
    },
    {
      variant: '4c',
      img: 'wall_4c.png'
    },
    {
      variant: '4d',
      img: 'wall_4d.png'
    }
  ]
};
