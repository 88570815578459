import React from 'react';
import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd';

import { LevelObjects } from '../../constants';
import DraggableItem from './draggableItem';

const GridSquare = (props) => {
  const cellContent = props.level[props.position.row][props.position.col];

  const [{ isOver, canDrop }, drop] = useDrop( () => ({
    accept: [ 'wall', 'monster' ],
    canDrop: () => {
      return cellContent === null;
    },
    drop: (item) => {
      if(item.position === null) {
        props.setItem(props.position, item);
      } else {
        props.moveItem(props.position, item);
      }
      props.changeLevelModified(true);
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    })
  }), [ cellContent ] );

  const { row, col } = props.position;
  const checkerClass = ( row%2 && col%2 ) || ( !(row%2) && !(col%2) ) ? 'checker' : '';

  const specs = cellContent === null ?
    null :
    LevelObjects[cellContent.type].find( (item) => item.variant === cellContent.variant );

  const cellChild = cellContent === null ?
    null :
    <DraggableItem specs={specs} type={cellContent.type} position={props.position}/>;

  return <div
    ref={drop}
    className={ [
      'gridCol',
      isOver ? (canDrop ? 'canDrop' : 'cannotDrop') : '',
      checkerClass
    ].join(' ') }
  >{cellChild}</div>;
};

GridSquare.propTypes = {
  level: PropTypes.array,
  moveItem: PropTypes.func,
  position: PropTypes.object,
  setItem: PropTypes.func,
  changeLevelModified: PropTypes.func
};

export default GridSquare;