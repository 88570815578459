import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { usePopper } from 'react-popper';

import { intToString } from 'utils';

const DurationSelector = ({ value, setValue, hasError }) => {
  const [show, setShow] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);

  const node = useRef();
  const clickHandlerRef = useRef();

  const { styles, attributes, update } = usePopper(
    referenceElement,
    popperElement, {
      modifiers: [
        { name: 'arrow', options: { element: arrowElement } }
      ],
    }
  );

  useEffect(() => {
    const clickCallback = (e) => {
      clickHandlerRef.current(e);
    };

    document.addEventListener('mousedown', clickCallback);
    return () => {
      document.removeEventListener('mousedown', clickCallback);
    };
  }, []);

  const timeString = intToString(value);
  const second = value % 60;
  const minute = Math.floor(value / 60);

  const handleMinuteClick = (val) => {
    setValue( val*60 + second );
  };

  const handleSecondClick = (val) => {
    setValue( minute*60 + val );
  };

  const handleClickOutside = (e) => {
    if(!show || node.current.contains(e.target)) {
      return;
    }

    setShow(false);
  };
  clickHandlerRef.current = handleClickOutside;

  const handleClick = (e) => {
    e.preventDefault();
    update();
    setShow(!show);
  };

  const classes = [
    'popover',
    show ? 'visible' : 'hidden'
  ].join(' ');

  const minuteElements = [];
  for(let time=0; time<=2; time++) {
    const className = 'timeElement' + ( time === minute ? ' selected' : ' notSelected' );
    minuteElements.push(<div
      className={className}
      onClick={ () => handleMinuteClick(time) }
      key={'m'+time}>{time}</div>);
  }

  const secondElements = [];
  for(let time=0; time<60; time+=5) {
    const className = 'timeElement' + ( time === second ? ' selected' : ' notSelected' );
    secondElements.push(<div
      className={className}
      onClick={ () => handleSecondClick(time) }
      key={'s'+time}>{time}</div>);
  }

  return (
    <div className="durationSelector" ref={node}>
      <input
        type='text'
        readOnly
        className={ hasError ? 'error' : '' }
        value={timeString}
        onClick={handleClick}
        ref={setReferenceElement}/>

      <div
        className={classes}
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}>
        <div ref={setArrowElement} className="popoverArrow" style={styles.arrow}></div>

        <div className="minuteSelector">
          {minuteElements}
        </div>
        <div className="secondSelector">
          {secondElements}
        </div>
      </div>
    </div>
  );
};

DurationSelector.propTypes = {
  value: PropTypes.number,
  hasError: PropTypes.bool,

  setValue: PropTypes.func
};

export default DurationSelector;