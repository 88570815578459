import {
  ACTION_SHOW_CONFIRM_MODAL,
  ACTION_HIDE_CONFIRM_MODAL,
  ACTION_SHOW_NEW_USER_MODAL,
  ACTION_SHOW_EDIT_USER_MODAL,
  ACTION_HIDE_USER_MODAL,
} from '../constants';

const initialState = {
  confirmModal: {
    show: false,
    title: '',
    message: '',
    confirmText: '',
    confirmAction: () => {}
  },
  userModal: {
    show: false
  },
};

export const showConfirmModal = (props) => ({
  type: ACTION_SHOW_CONFIRM_MODAL,
  ...props
});

export const showEditUserModal = (props) => ({
  type: ACTION_SHOW_NEW_USER_MODAL,
  ...props
});

export const showNewUserModal = () => ({
  type: ACTION_SHOW_NEW_USER_MODAL
});

export const hideConfirmModal = () => ({
  type: ACTION_HIDE_CONFIRM_MODAL
});

export const hideUserModal = () => ({
  type: ACTION_HIDE_USER_MODAL
});

const reducer = (state = initialState, action) => {
  switch(action.type) {

    case ACTION_SHOW_CONFIRM_MODAL:
      return {
        ...state,
        confirmModal: {
          title: action.title,
          message: action.message,
          confirmText: action.confirmText,
          confirmAction: action.confirmAction,
          show: true
        }
      };

    case ACTION_SHOW_NEW_USER_MODAL:
      return {
        ...state,
        userModal: {
          ...state.userModal,
          show: true
        }
      };

    case ACTION_SHOW_EDIT_USER_MODAL:
      return {
        ...state,
        userModal: {
          ...state.userModal,
          show: true
        }
      };


    case ACTION_HIDE_CONFIRM_MODAL:
      return {
        ...state,
        confirmModal: {
          ...state.confirmModal,
          show: false
        }
      };

    case ACTION_HIDE_USER_MODAL:
      return {
        ...state,
        userModal: {
          ...state.userModal,
          show: false
        }
      };

    default:
      return state;
  }
};

export default reducer;
