import React from 'react';

import {
  Section, Columns
} from 'react-bulma-components';

import ConfirmModal from 'components/editor/confirmModal';
import EditUserModal from 'components/admin/editUserModal';

import Header from 'components/admin/header';
import UserList from 'components/admin/userList';
import Notification from 'components/notification';

import '../sass/admin.scss';

const AdminView = () => {
  return (<div>
    <Header tabIndex="0"/>
    <Section tabIndex="0">
      <Columns centered={true}>
        <Columns.Column size={'half'}>
          <UserList/>
          <Notification className="adminNotification"/>
        </Columns.Column>
      </Columns>
    </Section>
    <EditUserModal/>
    <ConfirmModal/>
  </div>);
};

export default AdminView;