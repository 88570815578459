import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

export const Notification = (props) => {
  const { notification } = props;

  if(notification.message === '') {
    return null;
  }

  const classes = [
    props.className,
    `login_notification_${notification.msgType}`
  ].join(' ');

  return (<div className={ classes }>{ notification.message }</div>);
};

Notification.propTypes = {
  notification: PropTypes.object,
  className: PropTypes.string
};

const mapStateToProps = (state) => {
  return {
    notification: state.notification
  };
};

export default connect(mapStateToProps, null)(Notification);