import {
  ACTION_FETCH_USERS,
  ACTION_ADMIN_NEW_USER,
  ACTION_ADMIN_DEL_USER,
  ACTION_ADMIN_CHG_PASSWORD,
  RESULT_USER_FETCH_SUCCESS
} from '../constants';

export const fetchUsers = () => ({
  type: ACTION_FETCH_USERS
});

export const createUser = (user) => ({
  type: ACTION_ADMIN_NEW_USER,
  user
});

export const deleteUser = (id) => ({
  type: ACTION_ADMIN_DEL_USER,
  id
});

export const changeUserPassword = (id, password) => ({
  type: ACTION_ADMIN_CHG_PASSWORD,
  id, password
});

const reducer = (state = [], action) => {
  switch(action.type) {
    case RESULT_USER_FETCH_SUCCESS:
      return [ ...action.data ];
    default:
      return state;
  }
};

export default reducer;
