import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { Button, Navbar, Form } from 'react-bulma-components';

import { doLogout } from 'reducers/session';
import { doGoogleLogout } from 'utils/gauth';
import { changeDifficulty, changeLevel, setTutorialPhase } from 'reducers/settings';
import { exportLevelsAsJson, exportLevelsAsPdf } from 'reducers/levels';
import { showConfirmModal, hideConfirmModal } from 'reducers/modals';

import { LOGINTYPE_CLASSIC } from '../../constants';

const Header = (props) => {
  const [ open, setOpen ] = useState(false);

  const logoutEvent = async (e) => {
    e.preventDefault();
    if(props.session.loginType !== LOGINTYPE_CLASSIC) {
      await doGoogleLogout();
    }
    props.doLogout();
  };

  const onPdfClick = (e) => {
    e.preventDefault();
    props.exportLevelsAsPdf();
  };

  const onExportClick = (e) => {
    e.preventDefault();
    props.exportLevelsAsJson();
  };

  const onImportClick = (e) => {
    e.preventDefault();
    props.showImportModal(true);
  };

  const onTutorialClick = (e) => {
    e.preventDefault();
    props.setTutorialPhase(1);
  };

  const onAdminClick = (e) => {
    e.preventDefault();
    props.history.push('/admin');
  };

  const onSelectChange = (e) => {
    const newDifficulty = e.target.value;
    if(!props.settings.levelModified) {
      props.changeLevel(null, true);
      props.changeDifficulty(newDifficulty);
    } else {
      props.showConfirmModal({
        title: 'Change difficulty?',
        message: 'If you change difficulty now, you will lose all unsaved changes. Do you want to continue?',
        confirmText: 'Yes',
        confirmAction: () => {
          props.changeLevel(null, true);
          props.changeDifficulty(newDifficulty);
          props.hideConfirmModal();
        }
      });
    }
  };

  const adminLink = !props.isAdmin ? null : <Navbar.Item onClick={onAdminClick}>
    Admin area
  </Navbar.Item>;

  return (
    <Navbar active={open}>
      <Navbar.Brand>
        <Navbar.Burger active={open ? true : undefined} onClick={ () => setOpen(!open) } />
      </Navbar.Brand>
      <Navbar.Menu>
        <Navbar.Item renderAs="div">
          Difficulty: &nbsp;
          <Form.Select onChange={onSelectChange} value={props.settings.difficulty}>
            <option value="easy">Easy</option>
            <option value="medium">Medium</option>
            <option value="hard">Hard</option>
          </Form.Select>
        </Navbar.Item>
        <Navbar.Item onClick={ onPdfClick }>
          Create PDF
        </Navbar.Item>
        <Navbar.Item onClick={ onExportClick }>
          Export leveldata
        </Navbar.Item>
        <Navbar.Item onClick={ onImportClick }>
          Import leveldata
        </Navbar.Item>
        <Navbar.Container position="end">
          <Navbar.Item onClick={ onTutorialClick }>
            Show tutorial
          </Navbar.Item>
          {adminLink}
          <Navbar.Item renderAs="div">
            <Button onClick={ logoutEvent }>Logout</Button>
          </Navbar.Item>
        </Navbar.Container>
      </Navbar.Menu>
    </Navbar>
  );
};

Header.propTypes = {
  isAdmin: PropTypes.bool,
  settings: PropTypes.object,
  session: PropTypes.object,
  history: PropTypes.object,

  changeDifficulty: PropTypes.func,
  changeLevel: PropTypes.func,
  doLogout: PropTypes.func,
  exportLevelsAsJson: PropTypes.func,
  exportLevelsAsPdf: PropTypes.func,
  hideConfirmModal: PropTypes.func,
  setTutorialPhase: PropTypes.func,
  showConfirmModal: PropTypes.func,
  showImportModal: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    settings: state.settings
  };
};

export default withRouter(connect(mapStateToProps, {
  changeDifficulty,
  changeLevel,
  doLogout,
  exportLevelsAsJson,
  exportLevelsAsPdf,
  hideConfirmModal,
  setTutorialPhase,
  showConfirmModal,
})(Header));