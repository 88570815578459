import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Modal, Form, Button
} from 'react-bulma-components';

import { createUser } from '../../reducers/users';
import { hideUserModal } from 'reducers/modals';

const EditUserModal = (props) => {
  const [ accountType, setAccountType ] = useState('classic');
  const [ username, setUsername ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ gapiEmail, setGapiEmail ] = useState('');

  const {
    show
  } = props.modals.userModal;

  const close = () => {
    props.hideUserModal();
  };

  const changeAccountType = (e) => {
    setAccountType(e.target.value);
  };

  const resetValues = () => {
    setAccountType('classic');
    setUsername('');
    setPassword('');
    setGapiEmail('');
  };

  const onCreateUser = (e) => {
    e.preventDefault();
    props.createUser({
      accountType,
      username,
      password,
      gapiEmail
    });
    resetValues();
    props.hideUserModal();
  };

  const onCancel = (e) => {
    e.preventDefault();
    resetValues();
    props.hideUserModal();
  };

  const onUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const onPasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const onGapiEmailChange = (e) => {
    setGapiEmail(e.target.value);
  };

  const userNameField = accountType !== 'classic' ? null : <Form.Field>
    <Form.Label>Username:</Form.Label>
    <Form.Input type="text" value={username} onChange={onUsernameChange}/>
  </Form.Field>;

  const passwordField = accountType !== 'classic' ? null : <Form.Field>
    <Form.Label>Password:</Form.Label>
    <Form.Input type="text" value={password} onChange={onPasswordChange}/>
  </Form.Field>;

  const emailField = accountType !== 'gapi' ? null : <Form.Field>
    <Form.Label>GAPI email:</Form.Label>
    <Form.Input type="text" value={gapiEmail} onChange={onGapiEmailChange}/>
  </Form.Field>;

  return (
    <Modal show={show} onClose={close}>
      <Modal.Card>
        <Modal.Card.Header onClose={close}>
          <Modal.Card.Title>
            New User
          </Modal.Card.Title>
        </Modal.Card.Header>
        <Modal.Card.Body>
          <Form.Field>
            <Form.Label>Account type:</Form.Label>
            <Form.Control>
              <Form.Radio
                onChange={changeAccountType}
                checked={accountType === 'classic'}
                name="accountType"
                value="classic">Classic</Form.Radio>
              <Form.Radio
                onChange={changeAccountType}
                checked={accountType === 'gapi'}
                name="accountType"
                value="gapi">Google API</Form.Radio>
            </Form.Control>
          </Form.Field>

          {userNameField}

          {emailField}

          {passwordField}

          <Form.Field kind="group">
            <Form.Control>
              <Button onClick={onCreateUser} color="info">Create user</Button>
            </Form.Control>
            <Form.Control>
              <Button onClick={onCancel}>Cancel</Button>
            </Form.Control>
          </Form.Field>

        </Modal.Card.Body>
      </Modal.Card>
    </Modal>
  );
};

EditUserModal.propTypes = {
  modals: PropTypes.object,
  show: PropTypes.bool,

  createUser: PropTypes.func,
  hideUserModal: PropTypes.func,
  setShow: PropTypes.func
};

const mapStateToProps = (state) => ({
  modals: state.modals
});

export default connect(mapStateToProps, {
  createUser,
  hideUserModal
})(EditUserModal);