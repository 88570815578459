import { put,select } from 'redux-saga/effects';

import {
  ACTION_SET_NOTIFICATION,
  RESULT_USER_FETCH_SUCCESS,
  RESULT_ADM_NEW_SUCCESS,
  RESULT_ADM_PWD_SUCCESS,
  RESULT_ADM_DEL_SUCCESS,
  serverUri
} from '../constants';

import {
  getSession
} from '../selectors';

export function* doFetchUsers() {
  const session = yield select(getSession);
  const config = { headers: { Authorization: `bearer ${session.token}` } };

  try {
    const rawResponse = yield fetch(`${serverUri}/users`, config);
    const response = yield rawResponse.json();

    yield put({ type: RESULT_USER_FETCH_SUCCESS, data: response });
  } catch(error) {
    const errMsg = error.response.data.error;
    yield put({ type: ACTION_SET_NOTIFICATION,
      msgType: 'error',
      message: `Backend error: ${errMsg}`,
      duration: 5
    });
  }
}

export function* doCreateUser({ user }) {
  const session = yield select(getSession);
  const config = {
    headers: {
      Authorization: `bearer ${session.token}`,
      'Content-Type': 'application/json',
    }
  };

  try {
    yield fetch(
      `${serverUri}/users`,
      {
        ...config,
        body: JSON.stringify(user),
        method: 'POST'
      }
    );

    const rawResponse = yield fetch(`${serverUri}/users`, config);
    const response = yield rawResponse.json();

    yield put({ type: RESULT_USER_FETCH_SUCCESS, data: response });
    yield put({ type: RESULT_ADM_NEW_SUCCESS });
  } catch(error) {
    const errMsg = error.message;
    yield put({ type: ACTION_SET_NOTIFICATION,
      msgType: 'error',
      message: `Backend error: ${errMsg}`,
      duration: 5
    });
  }
}

export function* doDeleteUser({ id }) {
  const session = yield select(getSession);
  const config = { headers: { Authorization: `bearer ${session.token}` } };

  try {
    yield fetch(
      `${serverUri}/users/${id}`,
      { ...config, method: 'DELETE' }
    );

    const rawResponse = yield fetch(`${serverUri}/users`, config);
    const response = yield rawResponse.json();

    yield put({ type: RESULT_USER_FETCH_SUCCESS, data: response });
    yield put({ type: RESULT_ADM_DEL_SUCCESS });
  } catch(error) {
    const errMsg = error.message;
    yield put({ type: ACTION_SET_NOTIFICATION,
      msgType: 'error',
      message: `Backend error: ${errMsg}`,
      duration: 5
    });
  }
}

export function* doChangeUserPassword({ id, password }) {
  const session = yield select(getSession);
  const config = {
    headers: {
      Authorization: `bearer ${session.token}`,
      'Content-Type': 'application/json',
    }
  };

  try {
    yield fetch(`${serverUri}/users/${id}`,
      {
        ...config,
        method: 'PUT',
        body: JSON.stringify(password)
      }
    );
    yield put({ type: RESULT_ADM_PWD_SUCCESS });
  } catch(error) {
    const errMsg = error.response.data.error;
    yield put({ type: ACTION_SET_NOTIFICATION,
      msgType: 'error',
      message: `Backend error: ${errMsg}`,
      duration: 5
    });
  }
}

